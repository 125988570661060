import React from 'react';
import Error from '@/UI/form-control/error';
import styles from './checkbox.module.css';

export type IProps = {
    id?: string
    name: string
    label: string
    checked?: boolean
    disabled?: boolean
    required?: boolean
    arialabel?: string
    error?: string
    icon?: React.FC
    className?: string
    onChange?: React.ChangeEventHandler<HTMLInputElement>
    onBlur?: React.FocusEventHandler<HTMLInputElement>
}

const Checkbox = React.forwardRef<HTMLInputElement, IProps>(({
    id,
    name,
    label,
    checked,
    disabled,
    required,
    arialabel,
    error,
    icon,
    className = '',
    onChange,
    onBlur,
}, ref) => {
    const Icon = icon;

    return (
        <>
            <label
                htmlFor={id || name}
                className={`${styles.control} ${disabled ? styles.disabled : ''} ${className}`}
                data-testid="component-checkbox"
            >
                <div className={styles.wrapper}>
                    {required && (<sup className={styles.required}>*</sup>)}
                    <input
                        ref={ref}
                        id={id || name}
                        type="checkbox"
                        name={name}
                        value={name}
                        disabled={disabled}
                        checked={checked}
                        className={styles.checkbox}
                        aria-errormessage={`err_${id || name}`}
                        aria-invalid={!!error}
                        onChange={onChange}
                        onBlur={onBlur}
                    />
                    <span className={styles.icons}>
                        {Icon ? (
                            <Icon />
                        ) : (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                aria-hidden="true"
                                focusable="false"
                            >
                                <path
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="3"
                                    d="M1.73 12.91l6.37 6.37L22.79 4.59"
                                />
                            </svg>
                        )}
                    </span>
                    <span role={arialabel ? 'form' : undefined} aria-label={arialabel}>
                        {label}
                    </span>
                </div>

                {error && <Error id={`err_${id || name}`} className={styles.error}>{error}</Error>}
            </label>
        </>
    );
});

Checkbox.displayName = 'Checkbox';

export default Checkbox;
